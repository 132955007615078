import axios from 'axios'
import jwtDecode from 'jwt-decode'
import { HOST_API } from '../../config'

// Register developer
const register = async (developerData) => {
  const response = await axios.post(`${HOST_API}/developer/register`, developerData)
  let data

  if (response.data) {
    const { token } = response.data
    const { exp } = jwtDecode(token)

    data = { ...response.data, timeTokenExpires: exp }

    localStorage.setItem('developer', JSON.stringify(data))
  }

  return data
}

// Logout developer
const logout = async () => localStorage.removeItem('developer')

// Login developer
const login = async (developerData) => {
  const response = await axios.post(`${HOST_API}/developer/login`, developerData)
  let data

  if (response.data) {
    const { token } = response.data
    const { exp } = jwtDecode(token)

    data = { ...response.data, timeTokenExpires: exp }

    localStorage.setItem('developer', JSON.stringify(data))
  }

  return data
}

const authService = { register, logout, login }
export default authService
