import { m } from 'framer-motion'
// @mui
import { Container, Typography } from '@mui/material'
// hooks
import { useSelector } from 'react-redux'
// components
import { MotionContainer, varBounce } from 'components/animate'
// assets
import { ForbiddenIllustration } from 'assets'

// ----------------------------------------------------------------------

export default function RoleBasedGuard({ children, permission }) {
  const { developer } = useSelector((state) => state.auth)

  const permissions = developer?.role?.permissions || []

  if (permissions.some((item) => item.name.toLowerCase() === permission.toLowerCase())) {
    return <>{children}</>
  }

  return (
    <Container component={MotionContainer} sx={{ textAlign: 'center', mt: 5 }}>
      <m.div variants={varBounce().in}>
        <Typography variant="h3" paragraph>
          Permission Denied
        </Typography>
      </m.div>

      <m.div variants={varBounce().in}>
        <Typography sx={{ color: 'text.secondary' }}>You do not have permission to access this page</Typography>
      </m.div>

      <m.div variants={varBounce().in}>
        <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
      </m.div>
    </Container>
  )
}
