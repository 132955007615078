import { Suspense, lazy } from 'react'
import { Navigate, useRoutes, useLocation } from 'react-router-dom'
// Redux
import { useSelector } from 'react-redux'
// layouts
import DashboardLayout from './layouts/dashboard'
import LogoOnlyLayout from './layouts/LogoOnlyLayout'

// components
import LoadingScreen from './components/LoadingScreen'

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation()

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  )
}

// ----------------------------------------------------------------------

export default () =>
  useRoutes([
    {
      path: '/dashboard',
      element: (
        <ProtectedRoute>
          <DashboardLayout />
        </ProtectedRoute>
      ),
      children: [
        { path: 'tasks', element: <Tasks /> },
        { path: 'clicks', element: <Clicks /> },
        { path: 'leads', element: <Leads /> },
        { path: 'companies', element: <Companies /> },
        { path: 'companies_complete', element: <CompaniesComplete /> },
        { path: 'account', element: <Account /> },
        { path: 'developers', element: <Developers /> },
        { path: 'roles', element: <Roles /> },
        { path: 'roles_configuration', element: <RolesConfiguration /> },
        { path: 'logins', element: <Logins /> },
        // { path: 'permissions', element: <Permissions /> },
        {
          path: 'admin',
          children: [
            { element: <Navigate to="/dashboard/admin/permissions" replace />, index: true },
            { path: 'permissions', element: <PermissionListAdmin /> },
            { path: 'permissions_category', element: <PermissionCategoryListAdmin /> },
          ],
        },
        {
          path: 'prestadin',
          children: [
            { element: <Navigate to="/dashboard/prestadin/permissions" replace />, index: true },
            { path: 'permissions', element: <PermissionListPrestadin /> },
            { path: 'permissions_category', element: <PermissionCategoryListPrestadin /> },
          ],
        },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/tasks" /> },
        {
          path: 'login',
          element: (
            <RedirectToDashboard>
              <Login />
            </RedirectToDashboard>
          ),
        },
        {
          path: 'register',
          element: (
            <RedirectToDashboard>
              <Register />
            </RedirectToDashboard>
          ),
        },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ])

// ----------------------------------------------------------------------

const ProtectedRoute = ({ children }) => {
  const { developer } = useSelector((state) => state.auth)

  if (!developer) {
    return <Navigate to="/login" replace />
  }

  return children
}

// ----------------------------------------------------------------------

const RedirectToDashboard = ({ children }) => {
  const { developer } = useSelector((state) => state.auth)

  if (developer) {
    return <Navigate to="/dashboard/tasks" replace />
  }

  return children
}

// ----------------------------------------------------------------------

// Helper routes
const NotFound = Loadable(lazy(() => import('./pages/Page404')))

// Auth routes
const Login = Loadable(lazy(() => import('./pages/auth/Login')))
const Register = Loadable(lazy(() => import('./pages/auth/Register')))

// Admin routes
const Account = Loadable(lazy(() => import('./pages/dashboard/DeveloperAccount')))

const Tasks = Loadable(lazy(() => import('./pages/dashboard/TaskList')))
const Clicks = Loadable(lazy(() => import('./pages/dashboard/ClickList')))
const Leads = Loadable(lazy(() => import('./pages/dashboard/LeadList')))
const Companies = Loadable(lazy(() => import('./pages/dashboard/CompanyList')))

const CompaniesComplete = Loadable(lazy(() => import('./pages/dashboard/CompanyListComplete')))
const Logins = Loadable(lazy(() => import('./pages/dashboard/LoginList')))

const Developers = Loadable(lazy(() => import('./pages/dashboard/DeveloperList')))
const Roles = Loadable(lazy(() => import('./pages/dashboard/RoleList')))
const RolesConfiguration = Loadable(lazy(() => import('./pages/dashboard/RoleConfiguration')))

// Permission routes
const PermissionListAdmin = Loadable(lazy(() => import('./pages/dashboard/PermissionListAdmin')))
const PermissionCategoryListAdmin = Loadable(lazy(() => import('./pages/dashboard/PermissionCategoryListAdmin copy')))

const PermissionListPrestadin = Loadable(lazy(() => import('./pages/dashboard/PermissionListPrestadin')))
const PermissionCategoryListPrestadin = Loadable(
  lazy(() => import('./pages/dashboard/PermissionCategoryListPrestadin'))
)
