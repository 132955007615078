import { configureStore } from '@reduxjs/toolkit'
import authReducer from './auth/authSlice'
// import roleReducer from './role/roleSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    // role: roleReducer,
  },
})
