import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import authService from './authService'

// Get developer from localstorage
let developer = JSON.parse(localStorage.getItem('developer'))

// When open the website check TOKEN EXPIRATION
if (developer) {
  const expirationTime = developer.timeTokenExpires * 1000 - 5000

  if (Date.now() >= expirationTime) {
    developer = null
    localStorage.removeItem('developer')
  }
}

const initialState = {
  developer: developer || null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

export const register = createAsyncThunk('auth/register', async (developer, thunkAPI) => {
  try {
    return await authService.register(developer)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

    return thunkAPI.rejectWithValue(message)
  }
})

export const login = createAsyncThunk('auth/login', async (developer, thunkAPI) => {
  try {
    return await authService.login(developer)
  } catch (error) {
    let message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

    if (message === 'Network Error') message = 'Contactar a soporte'

    return thunkAPI.rejectWithValue(message)
  }
})

export const logout = createAsyncThunk('auth/logout', async () => {
  await authService.logout()
})

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isError = false
      state.isSuccess = false
      state.message = ''
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state) => {
        state.isLoading = true
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.developer = action.payload
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.developer = null
      })
      .addCase(logout.fulfilled, (state) => {
        state.developer = null
      })
      .addCase(login.pending, (state) => {
        state.isLoading = true
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.developer = action.payload
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.developer = null
      })
  },
})

export const { reset } = authSlice.actions
export default authSlice.reducer
