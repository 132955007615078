// component
import Iconify from '../../components/Iconify'

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />

const navConfig = [
  {
    subheader: 'general',
    items: [
      {
        title: 'tasks',
        path: '/dashboard/tasks',
        icon: getIcon('cil:list-rich'),
      },
      {
        title: 'clicks',
        path: '/dashboard/clicks',
        icon: getIcon('clarity:cursor-hand-click-line'),
      },
      {
        title: 'leads',
        path: '/dashboard/leads',
        icon: getIcon('eva:people-fill'),
      },
      {
        title: 'companies',
        path: '/dashboard/companies',
        icon: getIcon('fluent:people-money-20-filled'),
      },
      {
        title: 'All Companies',
        path: '/dashboard/companies_complete',
        icon: getIcon('fluent:people-money-20-filled'),
      },
      {
        title: 'Logins',
        path: '/dashboard/logins',
        icon: getIcon('carbon:two-factor-authentication'),
      },
      {
        title: 'developers',
        path: '/dashboard/developers',
        icon: getIcon('gis:globe-users'),
      },
      {
        title: 'Roles',
        path: '/dashboard/developers',
        icon: getIcon('eos-icons:role-binding'),
        children: [
          {
            title: 'list',
            path: '/dashboard/roles',
          },
          {
            title: 'configuration',
            path: '/dashboard/roles_configuration',
          },
        ],
      },
    ],
  },

  {
    subheader: 'config permissions',
    items: [
      {
        title: 'ADMIN',
        path: '/dashboard/admin',
        icon: getIcon('fa-solid:user-lock'),
        children: [
          {
            title: 'permissions',
            path: '/dashboard/admin/permissions',
          },
          {
            title: 'permission categories',
            path: '/dashboard/admin/permissions_category',
          },
        ],
      },
      {
        title: 'PRESTADIN',
        path: '/dashboard/prestadin',
        icon: getIcon('fa-solid:user-lock'),
        children: [
          {
            title: 'permissions',
            path: '/dashboard/prestadin/permissions',
          },
          {
            title: 'permission categories',
            path: '/dashboard/prestadin/permissions_category',
          },
        ],
      },
    ],
  },
]

export default navConfig
