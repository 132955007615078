let URL

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // dev code
  URL = 'http://localhost:3003/admin'
  //   URL = 'https://api.prestadin.com/admin'
} else {
  // production code
  URL = 'https://api.prestadin.com/admin'
}

export const HOST_API = URL
