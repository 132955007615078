import { useEffect } from 'react'
// Redux
import { useSelector, useDispatch } from 'react-redux'
import { logout } from '../features/auth/authSlice'

export default () => {
  const dispatch = useDispatch()
  const { developer } = useSelector((state) => state.auth)

  useEffect(() => {
    let intervalId

    if (developer) {
      intervalId = setInterval(() => {
        // Refresh the token a minute early to avoid latency issues
        const expirationTime = developer.timeTokenExpires * 1000 - 5000
        const dateNow = Date.now()

        if (dateNow >= expirationTime) {
          dispatch(logout())
        }
        // else {
        //   const minutesToExpire = (expirationTime - dateNow) / 60000
        //   console.log(minutesToExpire)
        // }
      }, 5000)
    }

    return () => {
      clearInterval(intervalId)
    }
  }, [developer, dispatch])
}
