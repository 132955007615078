import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
// routes
import Router from 'routes'
// theme
import ThemeProvider from 'theme'
// components
import ScrollToTop from 'components/ScrollToTop'
import { BaseOptionChartStyle } from 'components/chart/BaseOptionChart'
// hooks
import useAutoLogout from 'hooks/useAutoLogout'

// ----------------------------------------------------------------------

export default function App() {
  useAutoLogout()

  return (
    <ThemeProvider>
      <ToastContainer />
      <ScrollToTop />
      <BaseOptionChartStyle />
      <Router />
    </ThemeProvider>
  )
}
